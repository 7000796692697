import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['body']

  connect() {
    // if we've already loaded the content, don't load it again
    if (this.bodyTarget.innerHTML.trim() !== '') { return; }

    // fetch the content and fill the bodyTarget
    const controller = this;
    $.ajax({
      url: this.data.get('path'),
      type: 'GET',
      success: (response) => {
        controller.bodyTarget.innerHTML = response;
      }
    });
  }
}
