let deferredPrompt;
let addBtn;
let addP;

window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();

    // Stash the event so it can be triggered later.
    deferredPrompt = e;

    // Update UI to notify the user they can add to home screen
    addToHomescreenContainer = document.querySelector('#add-to-homescreen-container');
    addBtn = document.querySelector('#add-to-homescreen-btn');
    addP = document.querySelector('#add-to-homescreen-p');

    addToHomescreenContainer.classList.remove('d-none');
    addBtn.classList.remove('d-none');
    addP.classList.remove('d-none');

    addBtn.addEventListener('click', (e) => {
        // hide our user interface that shows our A2HS button
        addToHomescreenContainer.classList.add('d-none');
        addBtn.classList.add('d-none');
        addP.classList.add('d-none');

        // Show the prompt
        deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }
            deferredPrompt = null;
        });
    });
});
