import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['container', 'btn']

  connect() {
    $(this.btnTarget).fadeTo('slow', '1');
  }

  addOrRemove(event) {
    event.preventDefault();
    const controller = this;

    $(this.btnTarget).fadeTo('slow', '0');

    $.ajax({
      url: controller.data.get('url'),
      type: controller.data.get('method'),
      success: (response) => {
        $(controller.containerTarget).replaceWith(response);
      },
    });
  }
}
