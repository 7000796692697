import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['row']

  remove(event) {
    event.preventDefault();

    const path = event.currentTarget.getAttribute('href');

    if (this._isConfirm()) {
      $.ajax({
        type: 'DELETE',
        url: path,
        success: () => {
          $(this.rowTarget).fadeOut();
        }
      });
    }
  }

  _isConfirm() {
    return window.confirm('Are you sure you want to remove this song from your playlist?');
  }
}
