import { Controller } from 'stimulus';
import $ from 'jquery';
import debounce from 'lodash/debounce';

export default class extends Controller {
  static targets = ['searchBox', 'searchResultsContainer', 'loading']

  initialize() {
    this.fetchHtml = debounce(this.fetchHtml, 200).bind(this);
  }

  search() {
    this.fetchHtml();
  }

  fetchHtml() {
    const searchValue = this.searchBoxTarget.value;
    const controller = this;

    $.ajax({
      url: this.data.get('url'),
      data: { search: searchValue },
      type: 'GET',
      success: (response) => {
        controller.searchResultsContainerTarget.innerHTML = response;
      },
    });
  }

  _hideLoading() {
    // this.loadingTarget.classList.add('d-none');
  }

  _showLoading() {
    // this.loadingTarget.classList.remove('d-none');
  }
}
