import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  display(event) {
    event.preventDefault();

    const modal_holder_selector = '#modal-holder';
    const path = event.currentTarget.getAttribute('href');

    $.get(path, function(data) {
      $(modal_holder_selector).html(data) // fill the modal with our content
      $(modal_holder_selector).find('.modal').modal('show'); // show the modal
    });
  }

  // close the modal after successful ajax
  // $(document).on("ajax:success", "[data-modal-action='true']", function() {
  //   $("#modal-holder .modal").modal("toggle");
  //   return false;
  // });
}
