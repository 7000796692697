import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['body', 'addBtn', 'firstInputOnChild']

  connect() {
    console.log('CONNECTED')
  }

  addChild(event) {
    console.log('ADD')
    event.preventDefault();

    // ensure each child field is treated as a unique field, else only
    // the last child will be saved
    const time = new Date().getTime();
    const regexp = new RegExp(this.addBtnTarget.dataset.id, 'g');
    const fields = this.addBtnTarget.dataset.fields.replace(regexp, time);

    this.bodyTarget.insertAdjacentHTML('beforeend', fields);

    const justAdded = this.firstInputOnChildTargets.slice(-1)[0];
    justAdded.focus();
  }

  preventSubmit(event) {
    const pressedKey = event.keyCode;
    const returnKey = 13;

    if (pressedKey === returnKey) {
      event.preventDefault();

      // add another set of child fields
      this.addBtnTarget.click();

      return false;
    }

    return true;
  }
}
