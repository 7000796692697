import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['body', 'hiddenDestroyCheckbox']

  removeChild(event) {
    event.preventDefault();

    if (this._isConfirm()) {
      this.hiddenDestroyCheckboxTarget.value = '1';
      this.bodyTarget.setAttribute('style', 'display: none !important');
    }
  }

  _isConfirm() {
    return window.confirm('Are you sure you want to entirely delete this item?');
  }
}
