import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['body']

  connect() {
    $(this.bodyTarget).hide();
  }

  toggle(event) {
    event.preventDefault();

    $(this.bodyTarget).slideToggle();
  }
}
