import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['searchBox', 'item']

  filter() {
    const filterValue = this.searchBoxTarget.value.toLowerCase();

    this.itemTargets.forEach((item) => {
      const css = item.classList;
      const itemText = item.textContent.toLowerCase();

      if (filterValue === '' || filterValue === undefined) {
        css.remove('d-none');
      } else if (itemText.includes(filterValue)) {
        css.remove('d-none');
      } else {
        css.add('d-none');
      }
    });
  }
}
