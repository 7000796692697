import { Controller } from 'stimulus';
import $ from 'jquery';
import throttle from 'lodash/throttle';

export default class extends Controller {
  static targets = [
    'container', 'playerWrapper', 'player', 'playPauseBtn', 'mainPlayBtn', 'seeker', 'toggleTab',
    'currentTime', 'totalTime', 'songNameContent', 'artistContent', 'albumContent',
    'favorite', 'hide'
  ]

  connect() {
    // audio element has attribute 'data-turbolinks-permanent'. That allows the player to continue
    // playing between page loads; however, the player will play multiple times... to avoid that,
    // we use the connect() and disconnect() actions to briefly unset/re-set autoplay
    this.playerTarget.setAttribute('autoplay', true)

    this.element[this.identifier] = this;

    this._handlePageNavigation();
    this._handleShowHideSmallPlayer();
  }

  disconnect() {
    // see comment in connect() function
    this.playerTarget.removeAttribute('autoplay');

    // unbind the scroll event added by _handleShowHideSmallPlayer
    // TODO: This is a sledge hammer where we need mallet...it unbinds ALL scroll events from
    // document (not just the one we set in _handleShowHideSmallPlayer). Fortunately, I THINK
    // this is the only one (fingers crossed). However, if we ever add another ANYWHERE else,
    // yep...this little piece of code will wipe it out. Blah...
    $(document).unbind('scroll');
  }

  play(event) {
    event.preventDefault();

    const btn = event.target;
    this._togglePlayBtn(btn);

    if (this._isActiveSong(btn)) {
      this.playerTarget.paused ? this.playerTarget.play() : this.playerTarget.pause();
    } else {
      const target = event.currentTarget
      const songPath = target.getAttribute('href');

      this._updatePlayer(songPath);
      this.playerTarget.play();
    }
  }

  playNext() {
    const path = this.playerTarget.dataset.nextSongPath;

    this._updatePlayer(path);
  }

  seek() {
    const currentTime = this.playerTarget.currentTime;
    const duration = this.playerTarget.duration;

    // update the time tracker (e.g. 0:00 -> 0:01)
    let minutes = Math.floor(currentTime / 60).toString();
    let seconds = Math.floor(currentTime % 60).toString();
    seconds = seconds.length === 1 ? '0' + seconds : seconds
    this.currentTimeTarget.innerHTML = minutes + ':' + seconds;

    // move the seeker forward
    const percentProgress = (currentTime / duration) * 100;
    const styleWidth = percentProgress.toString() + "%";
    this.seekerTargets.forEach(element => $(element).width(styleWidth));

    // display the total duration
    minutes = Math.floor(duration / 60).toString();
    seconds = Math.floor(duration % 60).toString();
    seconds = seconds.length === 1 ? '0' + seconds : seconds
    this.totalTimeTarget.innerHTML = minutes + ':' + seconds;
  }

  seekClick(event) {
    // obtain relative position along container's width that user clicked on
    const $seekerContainer = $(event.currentTarget);
    const width = $seekerContainer.width();
    const clickPosition = event.pageX - $seekerContainer.offset().left;
    const percentOfWhole = clickPosition / width;

    // update the seeker's styling to reflect new position
    const seekerWidth = (percentOfWhole * 100).toString() + "%";
    this.seekerTargets.forEach(element => $(element).width(seekerWidth));

    // update actual audio track
    const seekTime = this.playerTarget.duration * percentOfWhole;
    this.playerTarget.currentTime = seekTime;
  }

  refreshFavoriteBtns() {
    this.favoriteTargets.forEach(element => element.favorites.refresh());
  }

  refreshHideBtns() {
    this.hideTargets.forEach(element => element.hides.refresh());
  }

  _isActiveSong(btn) {
    return btn.dataset.songId === this.playerTarget.dataset.currentSongId;
  }

  _displaySongInfo() {
    const dataset = this.playerTarget.dataset;

    this.songNameContentTarget.innerHTML = this.playerTarget.getAttribute('title');
    this.artistContentTarget.innerHTML = dataset.artistContent;
    this.albumContentTarget.innerHTML = dataset.albumContent;
    this.mainPlayBtnTargets.forEach(element => element.dataset.songId = dataset.currentSongId);
  }

  _updatePlayer(path) {
    const dataset = this.mainPlayBtnTarget.dataset;
    const data = {
      type: dataset.resourceType,
      id: dataset.resourceId,
      refresh_songs: dataset.refreshSongs
    }

    $.ajax({
      url: path,
      data,
      success: (response) => {
        // set player attributes
        this.playerTarget.src = response['src'];
        this.playerTarget.setAttribute('title', response['name']);

        // set player data
        const data = this.playerTarget.dataset;
        data.currentSongId = response['id'];
        data.artistContent = response['artist_names'];
        data.albumContent = response['album_names'];

        // update song-id for main play btn
        if (this.hasMainPlayBtnTarget) {
          const songId = this.playerTarget.dataset.currentSongId;
          this.mainPlayBtnTargets.forEach(element => element.dataset.songId = songId);
        }

        this._displaySongInfo();
        this.refreshFavoriteBtns();
        this.refreshHideBtns();
      },
    });
  }

  _togglePlayBtn(clickedBtn) {
    // prep by resetting all play/pause btns to show play (as if nothing is playing)
    this.playPauseBtnTargets.forEach(element => this._showPlay(element));

    if (!this.playPauseBtnTargets.includes(clickedBtn)) {
      // this is if replay/skip was clicked; hence we know we must show stream-all btn playing
      this._showPause(this.mainPlayBtnTarget)

    } else if (this._isActiveSong(clickedBtn)) {
      // check if clickedBtn is the active song, and then if player is paused vs playing
      if (this.playerTarget.paused) {
        this._showPause(clickedBtn)
        this._showPause(this.mainPlayBtnTarget);
      } else {
        this._showPlay(clickedBtn);
        this._showPlay(this.mainPlayBtnTarget);
      }

    // clicked a new play btn that isn't the active song
    } else {
      this._showPause(this.mainPlayBtnTarget);
      this._showPause(clickedBtn);
    }
  }

  _showPause(btn) {
    btn.classList.remove('fa-play-circle');
    btn.classList.add('fa-pause-circle');
    this._showPauseOnMainBtn();
  }

  _showPlay(btn) {
    btn.classList.remove('fa-pause-circle');
    btn.classList.add('fa-play-circle');
    this._showPlayOnMainBtn();
  }

  _showPlayOnMainBtn() {
    if (this.hasMainPlayBtnTarget) {
      this.mainPlayBtnTargets.forEach(element => element.classList.remove('fa-pause-circle'));
      this.mainPlayBtnTargets.forEach(element => element.classList.add('fa-play-circle'));
    }
  }

  _showPauseOnMainBtn() {
    if (this.hasMainPlayBtnTarget) {
      this.mainPlayBtnTargets.forEach(element => element.classList.remove('fa-play-circle'));
      this.mainPlayBtnTargets.forEach(element => element.classList.add('fa-pause-circle'));
    }
  }

  _handlePageNavigation() {
    // ensure song info isn't blank (if there's an active song)
    if (this.playerTarget.dataset.currentSongId) {
      this._displaySongInfo();
      this.seek();
    }

    // ensure main play/pause displays appropriate icon
    if (this.playerTarget.paused) {
      this._showPlayOnMainBtn();
    } else {
      this._showPauseOnMainBtn();
    }
  }

  _handleShowHideSmallPlayer() {
    const $mainPlayer = $('#main-player')

    function smallPlayerDisplay() {
      // on most normal pages, show/hide bottom player based on scroll position
      // otherwise, if main controls are hidden, always show the bottom player
      if ($mainPlayer.is(':visible')) {
        const top = $mainPlayer.offset().top;
        const height = $mainPlayer.outerHeight();
        const scrollHeight = top + height;

        if (window.scrollY > scrollHeight) {
          $('#audio-player-container').slideDown();
        } else {
          $('#audio-player-container').slideUp();
        }
      } else {
        $('#audio-player-container').show();
      }
    }

    $(document).on(
      'scroll turbolinks:load',
      throttle(smallPlayerDisplay, 200)
    );
  }
}
