import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['icon']

  connect() {
    // https://leastbad.com/stimulus-power-move
    // allow favorites controllers to be easily accessed
    this.element[this.identifier] = this;
  }

  favorite() {
    const controller = this;

    $.ajax({
      url: controller.data.get('url'),
      type: 'POST',
      dataType: 'text',
      success: (response) => {
        // refresh favorite btns on all audio controls
        document.body['audio-player'].refreshFavoriteBtns();
      },
      error: (response) => {
        console.log('ERROR:', response.responseText);

        const $modal = $('#modal-holder');
        $modal.html(response.responseText);
        $modal.find('.modal').modal('show');
      },
    });
  }

  refresh() {
    const controller = this;

    $.ajax({
      url: '/favorite',
      type: 'GET',
      dataType: 'text',
      success: (response) => {
        $(controller.iconTarget).replaceWith(response);
      },
      error: (response) => {
        console.log('failed to refresh favorites controller: ' + controller)
      },
    });
  }
}
